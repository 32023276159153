<template>
  <div class="page404">
    <h1 class="logo">
      <router-link to="/">
        <img src="@/assets/images/logo_404.svg" alt="TradeWaltz">
      </router-link>
    </h1>
    <div class="text">
      <img src="@/assets/images/404.png" alt="404 Whoops! It looks like you are lost!">
      <tw-button type="secondary" @click="goDashboard">Go Dashboard</tw-button>
    </div>
  </div>
</template>

<script>
import TwButton from '@/components/atoms/TwButton';

export default {
  name: 'Page404',
  components: {
    TwButton,
  },
  methods: {
    goDashboard() {
      this.$router.push('/');
    },
  }
};
</script>

<style lang="scss" scoped>
  .page404 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background: url(../assets/images/404_bg.svg) no-repeat center;
    background-size: cover;

    h1.logo {
      position: absolute;
      top: 74px;
      left: 49px;
      margin: 0;

      img {
        display: block;
        width: 184px;
      }
    }

    .text {
      position: absolute;
      top: 240px;
      left: 50%;
      margin-left: -230.5px;
      width: 461px;
      text-align: center;

      img {
        display: block;
        width: 100%;
        margin-bottom: 23px;
      }
    }

    .tw_button {
      box-shadow: none;
      border: 0.5px solid #EDEDF4;
    }
  }
</style>
