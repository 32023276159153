<template>
  <button type="button" :class="classes" :style="style" :disabled="disabled" @click.stop="click"><i v-if="icon" class="icon" />
    <slot />
  </button>
</template>

<script>
export default {
  name: 'TwButton',
  props: {
    type: {
      type: String,
      default: 'default',
      // validator: value => {
      //   return ['default', 'primary', 'secondary'].indexOf(value) !== -1;
      // }
    },
    size: {
      type: String,
      default: 'medium',
      // validator: value => {
      //   return ['small', 'medium', 'large'].indexOf(value) !== -1;
      // }
    },
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
    disabled: {
      type: Boolean,
    }
  },

  computed: {
    classes() {
      return {
        'tw_button': true,
        [`tw_button__${this.type || 'default'}`]: true,
        [`tw_button__${this.size || 'medium'}`]: true,
        'tw_button__icon': !!this.icon,
        [`icon_${this.icon}`]: !!this.icon,
      };
    },
    style() {
      return {
        color: this.color,
        backgroundColor: this.backgroundColor
      };
    },
  },
  methods: {
    click() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss">
.tw_button {
  font-weight: bold;
  border-radius: 24px;
  cursor: pointer;
  display: inline-block;
  border: 0.5px solid transparent;
  box-sizing: border-box;
  transition: box-shadow .2s;
  color: $color_white;
  vertical-align: middle;
  @include button_shadow;

  &.square {
    border-radius: 6px;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &:hover {
    @include button_hover_shadow;
  }

  &:active {
    transition: box-shadow .1s;
    @include button_active_shadow;
  }

  &:focus-visible {
    outline: 0;
    border-color: $color_primary_blue;
    @include button_focused_shadow;

    &:hover {
      @include button_hover_focused_shadow;
    }

    &:hover {
      @include button_hover_focused_shadow;
    }
  }
}
.tw_button__default {
  background-color: $color_gray_400;
}
.tw_button__primary {
  background-color: $color_dark_blue;
}
.tw_button__secondary {
  color: $color_dark_blue;
  background-color: $color_white;
  border: 0.5px solid $color_gray_300;

  &.black {
    color: $color_black;
  }
}
.tw_button__delete {
  background-color: #E7E7E7;
}

.tw_button__icon {
  padding-left: 12px;

  .icon {
    display: inline-block;
    margin-right: 8px;
    margin-top: -3px;
    vertical-align: middle;
    background: no-repeat center;
    background-size: cover;
  }
}

.tw_button__very_small {
  font-size: 12px;
  line-height: 1;
  padding: 4px 8px;
  border-radius: 11px;

  .icon {
    width: 16px;
    height: 16px;
  }
}
.tw_button__small {
  font-size: 14px;
  line-height: 20px;
  padding: 7px 24px;

  .icon {
    width: 16px;
    height: 16px;
  }
}
.tw_button__medium {
  font-size: 16px;
  line-height: 24px;
  padding: 7px 28px;

  .icon {
    width: 20px;
    height: 20px;
  }
}

.tw_button__login {
  width: 360px;
  font-size: 16px;
  line-height: 24px;
  padding: 13px 32px;
  border-radius: 6px;
  color: $color_white;
  background-color: $color_dark_blue;
}

.tw_button + .tw_button {
  margin-left: 20px;
}

.tw_button__icon {
  &.tw_button__small {
    padding-left: 12px;
  }

  &.tw_button__medium {
    padding-left: 14px;
  }

  &.icon_search .icon {
    background-image: url(../../assets/images/icons/icon_search.svg);
  }

  &.icon_plus .icon {
    background-image: url(../../assets/images/icons/icon_plus.svg);
  }

  &.icon_edit .icon {
    background-image: url(../../assets/images/icons/icon_edit.svg);
  }

  &.icon_menu .icon {
    margin-top: 0;
    background-image: url(../../assets/images/icons/icon_menu.svg);
  }

  &.icon_detail {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    .icon {
      width: 16px;
      height: 16px;
      margin: 0;
      background-image: url(../../assets/images/icons/icon_detail.svg);
      background-size: 16px auto;
    }
  }

  &.icon_graphical {
    padding: 5px 28px 5px 6px;
    border-radius: 100px;
    margin-bottom: 24px;
    .icon {
      margin-top: 0;
      width: 52px;
      height: 52px;
      background-image: url(../../assets/images/icons/icon_graphical.svg);
    }
  }

  &.icon_trash .icon {
    background-image: url(../../assets/images/icons/icon_trash.svg);
  }

  &.icon_chain .icon {
    background-image: url(../../assets/images/icons/icon_chain.svg);
  }

  &.icon_download {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 28px;
    height: 28px;
    border-radius: 100%;

    .icon {
      display: block;
      background-image: url(../../assets/images/icons/download.svg);
      background-size: 20px auto;
      width: 20px;
      height: 20px;
      margin: 0;
    }
  }

  &.icon_edit_attached_files {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 28px;
    height: 28px;
    border-radius: 100%;

    .icon {
      display: block;
      background-image: url(../../assets/images/icons/edit_attached_files.svg);
      //background-size: 20px auto;
      width: 15px;
      height: 16px;
      margin: 0;
      transform: translateX(1px);
    }
  }

  &.icon_link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 28px;
    height: 28px;
    border-radius: 100%;

    .icon {
      display: block;
      background-image: url(../../assets/images/icons/icon_link.svg);
      background-size: 20px auto;
      width: 20px;
      height: 20px;
      margin: 0;
    }
  }

  &.icon_vessel .icon {
    margin-top: 0;
    background-image: url(../../assets/images/icons/icon_vessel.svg);
  }

  // 取引先ID解除ボタン
  &.icon_id_with_cross {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    .icon {
      background-image: url(../../assets/images/icons/icon_id_with_cross.svg);
      width: 26px;
      height: 26px;
      margin: 0 0 0 1px;
    }
  }
}

// toggleボタン
.toggle_button {
  width: 80px;
  height: 36px;
  margin: 0 0 20px;
  padding: 0;

  > img {
    display: block;
    width: 24px;
    transition: all 0.2s;
    margin: 0 auto;
  }

  // &:after {
  //   content: 'Open';
  // }

  // &.open:after {
  //   content: 'Close';
  // }
  &.open > img {
    transform: rotate(180deg);
  }
}
</style>
